
import {Component, Vue} from 'vue-property-decorator';
import {Form} from 'element-ui';
import {
  platformConfigs,
  warehouse,
  phoneCodeRequests,
  wechatLoginRequests,
  roles,
  roleMappings,
} from '../../resources';
import {ObjectId} from 'bson';
import {getUserId} from '../../api/publicMethod';

@Component({})
export default class Login extends Vue {
  private validPhone = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^1[23456789]\d{9}$/.test(value)) {
      callback(new Error('请输入正确的手机号'));
    } else {
      callback();
    }
  };
  private formData = {
    code: '',
    phone: '',
  };
  private rules = {
    phone: [
      {required: true, message: '请输入手机号', trigger: 'blur'},
      {validator: this.validPhone, trigger: 'blur'},
    ],
    code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
  };
  private loginFlag = false;
  private codeText = '发送验证码';
  private codeFlag = false;
  private channelId: ObjectId | null = null; //渠道ID
  private applicationId: ObjectId | null = null; //应用ID
  private state = '';
  private userId = '';
  private requestId: ObjectId | null = null;

  async created() {
    this.requestId = ObjectId.createFromHexString(this.$route.params.id);
    //获取appid
    try {
      const platformConfig = (await platformConfigs.find(stage => stage)).find(
        () => true,
      );
      if (!platformConfig) {
        throw new Error('');
      }
      if (platformConfig.spec) {
        this.channelId = platformConfig.spec.channels[0].channelId;
        this.applicationId = platformConfig.spec.applicationId;
        this.$store.state.pConfig = platformConfig?.spec;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //发送验证码
  private async sentCode() {
    (this.$refs.formData as Form).validateField('phone', valid => {
      if (valid === '') {
        try {
          this.codeFlag = true;
          if (this.applicationId) {
            phoneCodeRequests
              .create(
                [
                  {
                    spec: {
                      phone: this.formData.phone,
                      applicationId: this.applicationId,
                      device: '浏览器',
                    },
                  },
                ],
                {
                  watch: {
                    filter: filter =>
                      filter(
                        f => f('operationType'),
                        e => e.$eq('update'),
                      )(
                        f => f('fullDocument')('status')('phase'),
                        e => e.$exists(true),
                      ),
                  },
                  fullResource: true,
                },
              )
              .then(res => {
                if (res[0].status?.phase === '成功') {
                  let time = 60;
                  const timer = setInterval(() => {
                    time--;
                    this.codeText = '已发送' + time + 's';
                    if (time === 0) {
                      this.codeFlag = false;
                      clearInterval(timer);
                      this.codeText = '重新发送';
                    }
                  }, 1000);
                } else {
                  this.codeFlag = false;
                  this.$message.error(
                    res[0].status?.conditions[0].message ?? '',
                  );
                }
              });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.codeFlag = false;
          this.$message.error('网络异常，请稍后重试');
        }
      }
    });
  }
  //绑定手机号
  private async phoneLogin() {
    (this.$refs.formData as Form).validate(valid => {
      if (valid) {
        try {
          this.loginFlag = true;
          if (this.requestId && this.channelId && this.applicationId) {
            wechatLoginRequests
              .create(
                [
                  {
                    spec: {
                      type: '电话验证码',
                      requestId: this.requestId,
                      phone: this.formData.phone,
                      code: this.formData.code,
                      channelId: this.channelId,
                      applicationId: this.applicationId,
                      device: '浏览器',
                    },
                  },
                ],
                {
                  watch: {
                    filter: filter =>
                      filter(
                        f => f('operationType'),
                        e => e.$eq('update'),
                      )(
                        f => f('fullDocument')('status')('phase'),
                        e => e.$exists(true),
                      ),
                  },
                  fullResource: true,
                },
              )
              .then(res => {
                if (res) {
                  if (res[0].status?.conditions[0].status) {
                    warehouse.token = res[0].status?.accessToken;
                    localStorage.setItem(
                      'refreshToken',
                      res[0].status?.refreshToken ?? '',
                    );
                    localStorage.setItem(
                      'accessToken',
                      res[0].status?.accessToken ?? '',
                    );
                    this.loginJump();
                  } else {
                    this.$message.error(
                      res[0].status?.conditions[0].message ?? '',
                    );
                  }
                }
              });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.$message.error('网络异常，请稍后重试');
        } finally {
          this.loginFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  private async loginJump() {
    //查询角色
    const role = await roles.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('name'),
          e => e.$eq('平台管理员'),
        ),
      ),
    );
    //查询用户拥有的角色
    const roleMapping = await roleMappings.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('userId'),
          e => e.$eq(getUserId()),
        )(
          f => f('spec')('roleId'),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          e => e.$in(role.map(v => v._id!)),
        ),
      ),
    );
    if (roleMapping.length > 0) {
      localStorage.setItem('roleName', '平台管理员');
    } else {
      localStorage.setItem('roleName', '应用管理员');
    }
    this.$router.push({path: '/home', query: {}});
  }
}
